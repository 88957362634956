import { FC, useMemo } from "react";
import { Link } from "@remix-run/react";
import joinClassNames from "classnames";

import ChevronIcon from "icons/chevron.svg?react";
import CardIcon from "icons/card.svg?react";
import TransactionsIcon from "icons/transactions.svg?react";
// import UsersIcon from "icons/users.svg?react";

import Button, { BlankButton } from "basics/button";

import { Drawer } from "components/dialog";

import { openSignInModal } from "modules/signin-modal";
// import { openReferralCodeModal } from "modules/referral-code-modal";

import { urlSelector } from "selectors/url";

import Advertisement from "./components/advertisement";
import { useConnect, useProfileDrawer } from "../../duck/hooks";
import { SettingsItem } from "../../duck/types";
import { SETTINGS_ITEMS, SUPPORT_ITEMS } from "../../duck/constants";

import profileDrawerClasses from "../../styles/classes.module.scss";
import classes from "./styles/classes.module.scss";

const Main: FC = () => {
  const {
    account,
    logout,
    isLoading,
    currency,
    isAuthorized,
    isCurrenciesLoaded,
  } = useConnect();
  const { changeScreen } = useProfileDrawer();

  const settingsItems = useMemo<SettingsItem[]>(
    () =>
      [
        {
          icon: CardIcon,
          text: "Currency",
          label: currency?.name,
          page: "currency",
        } as SettingsItem,
      ].concat(SETTINGS_ITEMS),
    [currency],
  );

  return (
    <>
      <Drawer.Header>
        <h2
          className={joinClassNames(profileDrawerClasses.title, classes.title)}
        >
          Profile
        </h2>
      </Drawer.Header>
      <Drawer.Body>
        <div className={classes.linksWrapper}>
          <Link className={classes.navigationLink} to={urlSelector.coins()}>
            Coins
          </Link>
          <Link className={classes.navigationLink} to={urlSelector.buy()}>
            Buy Crypto
          </Link>
          <Link className={classes.navigationLink} to={urlSelector.insights()}>
            Insights
          </Link>
          {/*<Link className={classes.navigationLink} to={urlSelector.points()}>*/}
          {/*  Points*/}
          {/*</Link>*/}
        </div>
        {account && (
          <div className={classes.emailWrapper}>
            <p className={classes.welcomeLabel}>Welcome to Coindisco!</p>
            <p className={classes.email}>{account.email}</p>
            {/*{!account.isInvited && (*/}
            {/*  <BlankButton*/}
            {/*    className={classes.redeemReferral}*/}
            {/*    onClick={() => {*/}
            {/*      openReferralCodeModal();*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    <UsersIcon />*/}
            {/*    <p className={classes.text}>Redeem invitation code</p>*/}
            {/*  </BlankButton>*/}
            {/*)}*/}
          </div>
        )}
        <div className={classes.sections}>
          <div>
            <p className={classes.label}>Settings</p>
            <div className={classes.buttonsWrapper}>
              {isAuthorized && (
                <Link
                  className={classes.link}
                  to={urlSelector.transactions()}
                  key="Transactions"
                >
                  <TransactionsIcon />
                  <p className={classes.text}>Transactions</p>
                  <ChevronIcon className={classes.chevronIcon} />
                </Link>
              )}
              {settingsItems.map(({ text, icon: Icon, label, page }) => (
                <BlankButton
                  className={classes.profileButton}
                  key={text}
                  onClick={() => changeScreen(page)}
                >
                  <Icon />
                  <p className={classes.text}>{text}</p>
                  <div className={classes.chevronWrapper}>
                    {page === "currency" && !isCurrenciesLoaded ? (
                      <div className={classes.labelSkeleton} />
                    ) : (
                      label && <p className={classes.currencyLabel}>{label}</p>
                    )}
                    <ChevronIcon className={classes.chevronIcon} />
                  </div>
                </BlankButton>
              ))}
            </div>
          </div>
          <div>
            <p className={classes.label}>Support</p>
            <div className={classes.buttonsWrapper}>
              {SUPPORT_ITEMS.map(({ href, text, icon: Icon }) => (
                <a
                  className={classes.link}
                  key={text}
                  href={href}
                  target="_blank"
                >
                  <Icon />
                  <p className={classes.text}>{text}</p>
                  <ChevronIcon className={classes.chevronIcon} />
                </a>
              ))}
            </div>
          </div>
          <div>
            <Button
              className={classes.signInOutButton}
              disabled={isLoading}
              isLoading={isLoading}
              themeName="primary"
              onClick={() => (account ? logout() : openSignInModal())}
            >
              {account ? "Sign Out" : "Sign In"}
            </Button>
          </div>
        </div>
        <div className={classes.spacer} />
        <Advertisement />
      </Drawer.Body>
    </>
  );
};

export default Main;
