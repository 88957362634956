import { FC } from "react";
import joinClassNames from "classnames";

import ChevronIcon from "icons/chevron.svg?react";

import { AddressStatuses } from "types/addresses";

import { openAddressModal } from "modules/address-modal";

import { getTruncatedAddress } from "selectors/addresses";

import { AddressIcon } from "components/icon";

import Loader from "basics/loader";
import { BlankButton } from "basics/button";

import { useConnect } from "./duck/hooks";

import profileDrawerClasses from "../../../../styles/classes.module.scss";
import classes from "./styles/classes.module.scss";

interface Props {
  id: Address["id"];
}

const Address: FC<Props> = ({ id }) => {
  const {
    addressEntity: { address, addressName, network },
    status,
  } = useConnect(id);

  const truncatedAddress = getTruncatedAddress(address);

  const isEditingOrRemoving =
    status === AddressStatuses.editing || status === AddressStatuses.removing;

  return (
    <BlankButton
      className={joinClassNames(profileDrawerClasses.listItem, classes.listItem)}
      role="listitem"
      onClick={() => {
        openAddressModal(id);
      }}
    >
      <AddressIcon className={classes.addressIcon} size="md" address={address} />
      <div className={classes.infoWrapper}>
        <p className={classes.title}>{addressName || truncatedAddress}</p>
        <div className={classes.textWrapper}>
          {addressName ? (
            <>
              <p className={classes.text}>{truncatedAddress}</p>
              <div className={classes.dot}>
                &bull;
              </div>
            </>
          ) : null}
          <div className={classes.label}>
            {network.name}
          </div>
        </div>
      </div>
      {isEditingOrRemoving ? (
        <Loader
          className={classes.loader}
          themeName={
            status === AddressStatuses.editing ? "default" : "destructive"
          }
          size="sm"
        />
      ) : (
        <ChevronIcon className={classes.chevronIcon} />
      )}
    </BlankButton>
  );
};

export default Address;
