import { FC, useRef } from "react";
import { FormProvider, useForm, useValidate } from "recalculate-form";

import { Modal } from "components/dialog";

import Input from "basics/input";
import Button from "basics/button";

import { useConnect } from "./duck/hooks";
import SubmitButton from "./components/submit-button";
import { MAX_ADDRESS_LENGTH } from "./duck/constants";

import classes from "./styles/classes.module.scss"

interface Props {
  id: Address["id"];
}

const AddressModal: FC<Props> = ({ id }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const {
    initialAddressName,
    address,
    onUpdateAddress,
    onDeleteAddress,
    isEditing,
    isRemoving,
  } = useConnect(id);

  const form = useForm({
    defaultValues: { addressName: initialAddressName || "" },
  });

  const isDisabled = isEditing || isRemoving;

  const submitHandler = ({
    values: { addressName },
  }: {
    values: { addressName: string };
  }) => {
    form.resetErrors();

    if (addressName.length > MAX_ADDRESS_LENGTH) {
      form.setErrors({
        addressName: `Address name should not be more than ${MAX_ADDRESS_LENGTH} symbols`,
      });
      inputRef.current?.focus();

      return;
    }

    onUpdateAddress(addressName);
  };

  useValidate(() => null, [], form);

  return (
    <Modal className={classes.modal}>
      <Modal.Header>Manage address</Modal.Header>
      <FormProvider form={form}>
        <form
          className={classes.contentForm}
          onSubmit={form.handleSubmit(submitHandler)}
        >
          <Input
            className={classes.addressInput}
            label="Wallet address"
            readOnly
            size="md"
            value={address}
          />
          <Input
            ref={inputRef}
            size="md"
            autoComplete="off"
            autoFocus
            label="Set address name"
            name="addressName"
            placeholder="Address name"
          />
          <div className={classes.buttonToolbar}>
            <SubmitButton isDisabled={isDisabled} isLoading={isEditing} />
            <Button
              className={classes.deleteAddressButton}
              themeName="ghost"
              disabled={isDisabled}
              isLoading={isRemoving}
              onClick={onDeleteAddress}
            >
              Delete address
            </Button>
          </div>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default AddressModal;
