import { FC } from "react";
import { useError, useWatch } from "recalculate-form";

import Button from "basics/button";

import classes from "./styles/classes.module.scss"

interface Props {
  isDisabled: boolean;
  isLoading: boolean;
}

const SubmitButton: FC<Props> = ({ isDisabled, isLoading }) => {
  const addressName = useWatch("addressName");
  const { errors } = useError();

  return (
    <Button
      className={classes.button}
      themeName="primary"
      type="submit"
      isBlock
      isLoading={isLoading}
      disabled={isDisabled || !addressName || Boolean(errors.addressName)}
    >
      Save changes
    </Button>
  );
};

export default SubmitButton;
